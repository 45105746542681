import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { 
    EntendaBody, JoinUs, Indicators
} from '../components';

import theme from '../styles/theme';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h4`
  font-size: ${theme.fontSizeLg * 0.9}px;
  font-weight: ${theme.bodyWeight};
  margin: 40px 0 15px 0;
`;

const Line = styled.div`
  height: 3px;
  width: 60px;
  background-color: ${theme.colors.primary};
  margin: 0 0 40px 0;
`;

const GrayLine = styled.div`
  background-color: ${theme.colors.grey2};
  height: 1px;
  width: 85%;
  margin: 60px 0 0px 0;
`;



const JoinWrapper = styled.div`
  width: 85%;
  min-height: 350px;
`;




const UnderstandPage = () => {
  return (
    <Wrapper>
      <Title>ENTENDA O TRUST</Title>
      <Line />

      <EntendaBody />
      <GrayLine />      
      <Title>INDICADORES AVALIADOS</Title>
      <Line />

      <Indicators />
      <GrayLine />
      <JoinWrapper>
        <JoinUs />
      </JoinWrapper>
    </Wrapper>
  );
};

export default UnderstandPage;

export const pageQuery = graphql`
  query EntendaQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
